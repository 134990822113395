import type { User } from '@clerk/nextjs/server';
import type { UserResource } from '@clerk/types';

export function getUsernameFromUser(
  user: User | UserResource | null | undefined,
): string | undefined {
  return (
    user?.emailAddresses.find(
      (email) => email.verification?.status === 'verified',
    )?.emailAddress || user?.emailAddresses[0]?.emailAddress
  );
}
