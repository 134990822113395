'use client';

import { enUS } from '@clerk/localizations';
import { ClerkProvider, useAuth, useUser } from '@clerk/nextjs';
import { NuqsAdapter } from 'nuqs/adapters/next/app';
import type { ReactNode } from 'react';

import ToastProvider from '@/components/designSystem/library/alerts/toasts/ToastProvider';
import { Route } from '@/constants/routes';
import { getUsernameFromUser } from '@/utils/functions/auth/getUsernameFromUser';

import { CSPostHogProvider } from './PostHogProvider';
import { ReactQueryProvider } from './ReactQueryProvider';

interface ProvidersProps {
  children: ReactNode;
}

function PostHogWrapper({ children }: { children: ReactNode }) {
  const { userId, orgId } = useAuth();
  const { user } = useUser();

  const username = getUsernameFromUser(user) ?? 'anonymous';

  const name = user
    ? `${user.firstName} ${user.lastName}`.trim()
    : 'Name not set';

  return (
    <CSPostHogProvider
      username={username}
      organizationId={orgId ?? undefined}
      name={name}
      userId={userId ?? 'anonymous'}
    >
      {children}
    </CSPostHogProvider>
  );
}

export function Providers({ children }: ProvidersProps) {
  return (
    <ClerkProvider
      localization={enUS}
      signInUrl={Route.SignIn}
      signUpUrl={Route.SignUp}
      signInFallbackRedirectUrl={Route.Dashboard}
      signUpFallbackRedirectUrl={Route.Welcome}
    >
      <ReactQueryProvider>
        <PostHogWrapper>
          <NuqsAdapter>
            {children}
            <ToastProvider />
          </NuqsAdapter>
        </PostHogWrapper>
      </ReactQueryProvider>
    </ClerkProvider>
  );
}
