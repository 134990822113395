import toast, { ToastBar, Toaster } from 'react-hot-toast';

const ToastProvider = () => {
  return (
    <Toaster
      position="bottom-right"
      reverseOrder={false}
      toastOptions={{
        duration: 5000,
        error: {
          iconTheme: {
            primary: 'rgb(185 28 28)',
            secondary: 'white',
          },
        },
        success: {
          iconTheme: {
            primary: 'rgb(21 128 61)',
            secondary: 'white',
          },
        },
      }}
    >
      {(t) => (
        <div
          role="button"
          tabIndex={0}
          className="w-auto cursor-pointer"
          aria-label="Dismiss notification"
          onClick={() => toast.dismiss(t.id)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              toast.dismiss(t.id);
            }
          }}
        >
          <ToastBar toast={t} />
        </div>
      )}
    </Toaster>
  );
};

export default ToastProvider;
